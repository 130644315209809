<template>
  <div
    :class="[isDarkMode ? 'dark' : null]"
    class="w-full h-screen sm:overflow-auto sm:h-full"
    ref="myele"
  >
    <div
      class="w-full h-full min-h-screen font-regular text-[22px] leading-[32px] max-sm:bg-white fold:bg-white max-sm:dark:bg-[#111119] fold:dark:bg-[#111119] dark:bg-[#111119] sm:overflow-auto"
      v-if="!serviceCheck"
    >
      <div class="fixed z-[50]" v-if="isModalOpen === true">
        <LayoutModal v-if="selectedModal == 'LayoutModal'" />
        <SoundSetting v-if="selectedModal == 'SoundSetting'" />
        <InfluencerApplication
          v-if="selectedModal == 'InfluencerApplication'"
        />
        <InfluencerApplication_2
          v-if="selectedModal == 'InfluencerApplication_2'"
        />
        <NicknameSetting v-if="selectedModal == 'NicknameSetting'" />
        <NicknameSetting_2 v-if="selectedModal == 'NicknameSetting_2'" />
        <ContractDetails v-if="selectedModal == 'ContractDetails'" />
        <WithdrawOrder v-if="selectedModal == 'WithdrawOrder'" />
        <FeeInformation v-if="selectedModal == 'FeeInformation'" />
        <AuthenticationGoogle v-if="selectedModal == 'AuthenticationGoogle'" />
        <GoogleQr v-if="selectedModal == 'GoogleQr'" />
        <GoogleNumber v-if="selectedModal == 'GoogleNumber'" />
        <!-- google otp 인증 -->
        <GoogleNumber2 v-if="selectedModal == 'GoogleNumber2'" />
        <EmailNumber v-if="selectedModal == 'EmailNumber'" />
        <GoogleCompleted v-if="selectedModal == 'GoogleCompleted'" />
        <ChangePassword v-if="selectedModal == 'ChangePassword'" />
        <PasswordEmail v-if="selectedModal == 'PasswordEmail'" />
        <OtpFail v-if="selectedModal == 'OtpFail'" />
        <EmailFail v-if="selectedModal == 'EmailFail'" />
        <PhoneFail v-if="selectedModal == 'PhoneFail'" />
        <CoinSearch v-if="selectedModal == 'CoinSearch'" />
        <SelectLang v-if="selectedModal == 'SelectLang'" />

        <PasswordForgot v-if="selectedModal == 'PasswordForgot'" />
        <PasswordReset v-if="selectedModal == 'PasswordReset'" />

        <SnsVerification v-if="selectedModal == 'SnsVerification'" />
        <!-- 거래하기 출금신청 상세정보 -->
        <WithdrawDetail v-if="selectedModal == 'WithdrawDetail'" />
        <!-- 거래하기 증거금 수정-->
        <MarginCorrection v-if="selectedModal == 'MarginCorrection'" />
        <!-- 거래하기 청산하기 -->
        <PayOff v-if="selectedModal == 'PayOff'" />
        <!-- 거래하기 공매수 레버리지 설정-->
        <ShortbuyLeverage v-if="selectedModal == 'ShortbuyLeverage'" />
        <!-- 거래하기 공매도 레버리지 설정-->
        <ShortsellingLeverage v-if="selectedModal == 'ShortsellingLeverage'" />
        <!-- 거래하기 공유버튼 누르면 뜨는 모달 -->
        <ShareDownload v-if="selectedModal == 'ShareDownload'" />
        <!-- 거래하기 목표가/손절가 -->
        <StopLimit v-if="selectedModal == 'StopLimit'" />
        <!-- 거래하기 무기한 선물계약 증거금 방식 -->
        <MarginMethod v-if="selectedModal == 'MarginMethod'" />
        <MoneyTransfer v-if="selectedModal == 'MoneyTransfer'" />
        <!-- 휴대폰인증 -->
        <PhoneNumber v-if="selectedModal == 'PhoneNumber'" />
        <!-- 피싱방지코드 -->
        <AntiPhishing v-if="selectedModal == 'AntiPhishing'" />
        <!-- 주문 전송 컨펌 모달 -->
        <OrderConfirm v-if="selectedModal == 'OrderConfirm'" />
        <!-- 출금레벨올리기 -->
        <WithdrawLevel v-if="selectedModal === 'WithdrawLevel'" />
        <!-- 친구초대코드 -->
        <InviteCode v-if="selectedModal === 'InviteCode'" />
        <!-- 초대코드 수정 -->
        <InvitecodeRevise v-if="selectedModal === 'InvitecodeRevise'" />
        <!-- 인플루언서 심사결과 -->
        <InfluencerResult v-if="selectedModal === 'InfluencerResult'" />
        <!-- 신분증 인증 -->
        <IdVerification v-if="selectedModal === 'IdVerification'" />
        <!-- 구글로그인모바일 -->
        <GoogleLogin v-if="selectedModal === 'GoogleLogin'" />
        <!-- 개인정보정책 -->
        <TermPolicy v-if="selectedModal === 'TermPolicy'" />
        <!-- 신분증인증 실패 -->
        <IdFail v-if="selectedModal === 'IdFail'" />
        <!-- 거래수수료 할인모달 -->
        <DiscountFee v-if="selectedModal === 'DiscountFee'" />
        <!-- 나의초대정보 물음표누르면 뜨는 모달 -->
        <InviteInfo v-if="selectedModal === 'InviteInfo'" />
        <!-- 초대랭킹보드 물음표누르면 뜨는 모달 -->
        <LankingInfo v-if="selectedModal === 'LankingInfo'" />
        <!-- 모바일 현물내역 모달 -->
        <AssetList v-if="selectedModal === 'AssetList'" />
        <!-- 모바일 선물내역 모달 -->
        <GiftList v-if="selectedModal === 'GiftList'" />
        <!-- 모바일 고객센터 문의내역 상세 -->
        <InquiryMobile v-if="selectedModal === 'InquiryMobile'" />
      </div>
      <!-- 모달 -->
      <NationNum v-if="nationNumState == true" />
      <!-- 기본모달 -->
      <AlertModal class="z-[100]" v-if="alertOpen === true" />
      <!-- 미로그인 얼럿 창 -->
      <LoginConfirm class="z-40" v-if="loginModal === true" />

      <div
        class="w-full min-h-screen sm:flex sm:flex-col sm:justify-between sm:items-center"
        v-if="loading"
      >
        <TopBar
          class="fixed z-10 w-full sm:top-0 max-sm:bottom-0 fold:bottom-0"
          :class="[
            $isMobile() &&
            ($route.path == '/mypage/active' || $route.path == '/side')
              ? 'hidden'
              : ''
          ]"
          @logout="logoutHandle"
        />
        <router-view class="z-0 w-full min-h-full sm:mt-20" />
        <MainFooter
          class="w-full max-sm:hidden fold:hidden"
          v-if="$route.path !== '/position'"
        />
        <PopUp class="fixed top-0 z-10 w-full" v-if="isPop" />
        <SplashView
          class="fixed top-0 z-20 w-full h-full opacity-70"
          v-if="!serverLoading"
        />
      </div>
      <SplashView class="fixed w-full h-full" v-if="!loading" />
    </div>
    <SystemUpdate class="mx-auto" v-else />
  </div>
</template>

<script>
import SplashView from './components/SplashView';
import TopBar from './components/TopBar';
import MainFooter from './components/MainFooter';
import NationNum from './components/Modal/NationNum';
import LayoutModal from './components/Modal/LayoutModal';
import SoundSetting from './components/Modal/SoundSetting';
import InfluencerApplication from './components/Modal/InfluencerApplication';
import NicknameSetting from './components/Modal/NicknameSetting';
import ContractDetails from './components/Modal/ContractDetails';
import WithdrawOrder from './components/Modal/WithdrawOrder';
import FeeInformation from './components/Modal/FeeInformation';
import AuthenticationGoogle from './components/Modal/AuthenticationGoogle';
import GoogleQr from './components/Modal/GoogleQr';
import GoogleNumber from './components/Modal/GoogleNumber';
import GoogleNumber2 from './components/Modal/GoogleNumber2';
import GoogleCompleted from './components/Modal/GoogleCompleted';
import InfluencerApplication_2 from './components/Modal/InfluencerApplication_2';
import ChangePassword from './components/Modal/ChangePassword';
import PasswordEmail from './components/Modal/PasswordEmail';
import NicknameSetting_2 from './components/Modal/NicknameSetting_2';
import OtpFail from './components/Modal/OtpFail';
import PhoneFail from './components/Modal/PhoneFail';
import EmailFail from './components/Modal/EmailFail';
import CoinSearch from './components/Modal/CoinSearch';
import SelectLang from './components/Modal/SelectLang';
import LoginConfirm from './components/Modal/LoginConfirm';
import EmailNumber from './components/Modal/EmailNumber';
import PasswordForgot from './components/Modal/PasswordForgot';
import PasswordReset from './components/Modal/PasswordReset';
import SnsVerification from './components/Modal/SnsVerification';
import WithdrawDetail from './components/Modal/WithdrawDetail';
import MarginCorrection from './components/Modal/MarginCorrection';
import PayOff from './components/Modal/PayOff';
import ShortbuyLeverage from './components/Modal/ShortbuyLeverage';
import ShortsellingLeverage from './components/Modal/ShortsellingLeverage';
import ShareDownload from './components/Modal/ShareDownload';
import StopLimit from './components/Modal/StopLimit';
import MarginMethod from './components/Modal/MarginMethod';
import MoneyTransfer from './components/Modal/MoneyTransfer';
import SignupCompleted from './components/Modal/SignupCompleted';
import PhoneNumber from './components/Modal/PhoneNumber';
import AntiPhishing from './components/Modal/AntiPhishing';
import OrderConfirm from './components/Modal/OrderConfirm';
import AlertModal from './components/Modal/AlertModal';
import WithdrawLevel from './components/Modal/WithdrawLevel';
import InviteCode from './components/Modal/InviteCode';
import InvitecodeRevise from './components/Modal/InvitecodeRevise';
import InfluencerResult from './components/Modal/InfluencerResult';
import IdVerification from './components/Modal/IdVerification';
import GoogleLogin from './components/Modal/GoogleLogin';
import DiscountFee from './components/Modal/DiscountFee';
import InviteInfo from './components/Modal/InviteInfo';
import LankingInfo from './components/Modal/LankingInfo';
import AssetList from './components/Modal/AssetList';
import GiftList from './components/Modal/GiftList';
import InquiryMobile from './components/Modal/InquiryMobile';
import PopUp from './components/PopUp';

import axios from 'axios';
import TermPolicy from './components/Modal/TermPolicy';
import IdFail from './components/Modal/IdFail';
import SystemUpdate from './components/SystemUpdate';

//zustand import
import { defineComponent } from 'vue';
import create from 'vue-zustand';
import {
  nationNumModal,
  layoutModal,
  soundModal,
  darkMode,
  modalControl,
  isLogin,
  popupState,
  userSetting,
  apiLanguage,
  serverLoad,
  topNotice,
  system,
  checkMobile
} from './store/store';
import {
  markListStore,
  tickSocketData,
  aggSocketData,
  tradeSocketData,
  markSocketData,
  chartRender,
  orderConfirm
} from '@/store/positionStore';
import Request from '@/utils/Request';

export default defineComponent({
  name: 'App',
  data() {
    return {
      isMobileLandscape: false,
      loading: false,
      update: true,
      allMarkData: {
        btc: {},eth: {},xrp: {},doge: {},matic: {},
        arb: {},sol: {},stx: {},sei: {},gas: {}, avax: {}, axs: {}, pol: {},
        etc: {}, ens: {}, sui: {}, apt:{}, sand:{}, pepe:{}, shib:{}, zro:{}, uni:{}, ondo:{}, jasmy:{}, mew:{}
      },
      selectedModal: '',
      currentModal: null,
      socketConnection: {
        aggTrade: null,
        ticker: null,
        mark: null,
        depth: null
      },
      recentTime: '',
      socketRefresh: false,
      timeArray: ['030000', '070000', '110000', '150000', '190000', '230000'],
      agent: '',
      //tickerArray: ["btc","eth","xrp","doge","arb","sol","stx","sei","gas","avax","axs","pol","sui","pepe","shib","ondo","apt","etc","ens"]
      tickerArray: ["btc","eth","xrp","sol","doge","avax","shib","sui","apt","pepe","ondo","etc","pol","arb","sei","stx","ens","axs","gas"]
      //tickerArray: ["btc","eth","xrp","doge","arb","sol","stx","sei","gas","avax","axs","pol","etc","ens","sui","apt","sand","pepe","shib","zro","uni","ondo","jasmy","mew"]
    };
  },
  provide() {
    return {
      tickerArray: this.tickerArray
    }
  },
  components: {
    SplashView,
    TopBar,

    // 모달 컴포넌트
    NationNum,
    LayoutModal,
    SoundSetting,
    InfluencerApplication,
    NicknameSetting,
    ContractDetails,
    WithdrawOrder,
    FeeInformation,
    AuthenticationGoogle,
    GoogleQr,
    GoogleNumber,
    GoogleNumber2,
    GoogleCompleted,
    InfluencerApplication_2,
    ChangePassword,
    PasswordEmail,
    NicknameSetting_2,
    OtpFail,
    EmailFail,
    CoinSearch,
    SelectLang,
    MainFooter,
    EmailNumber,
    PhoneFail,
    PasswordForgot,
    PasswordReset,
    SnsVerification,
    WithdrawDetail,
    LoginConfirm,
    MarginCorrection,
    PayOff,
    ShortbuyLeverage,
    ShortsellingLeverage,
    ShareDownload,
    StopLimit,
    MarginMethod,
    MoneyTransfer,
    SignupCompleted,
    PhoneNumber,
    AntiPhishing,
    OrderConfirm,
    AlertModal,
    WithdrawLevel,
    InviteCode,
    InvitecodeRevise,
    InfluencerResult,
    IdVerification,
    GoogleLogin,
    PopUp,
    TermPolicy,
    IdFail,
    DiscountFee,
    InviteInfo,
    LankingInfo,
    AssetList,
    GiftList,
    InquiryMobile,
    SystemUpdate
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { nationNumState } = useNationModal();
    const useLayoutModal = create(layoutModal);
    const { layoutModalState } = useLayoutModal();
    const useSoundModal = create(soundModal);
    const { soundModalState } = useSoundModal();
    const useDarkMode = create(darkMode);
    const { isDarkMode, setDarkMode } = useDarkMode();

    const useModalControl = create(modalControl);
    const { isModalOpen, whatModal, setModalName, setModalControl, alertOpen } =
      useModalControl();

    const useIsLogin = create(isLogin);
    const { setLogin, setLogout, loginModal, loginState } = useIsLogin();
    const usePopupState = create(popupState);
    const { isPop } = usePopupState();
    const useMarkListStore = create(markListStore);
    const { setMarkList, markData } = useMarkListStore();
    const useTickSocketData = create(tickSocketData);
    const { setTickData } = useTickSocketData();
    const useAggSocketData = create(aggSocketData);
    const { setAggData } = useAggSocketData();
    const useUserSetting = create(userSetting);
    const { setOption } = useUserSetting();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useTradeSocketData = create(tradeSocketData);
    const { setTradeData } = useTradeSocketData();
    const useMarkSocketData = create(markSocketData);
    const { setMarkData } = useMarkSocketData();
    const useServerLoad = create(serverLoad);
    const { serverLoading } = useServerLoad();
    const useTopNotice = create(topNotice);
    const { setIsMainTrue } = useTopNotice();
    const useSystem = create(system);
    const { serviceCheck, setServiceCheck } = useSystem();
    const useChartRender = create(chartRender);
    const { setChartOpen } = useChartRender();
    const useMobile = create(checkMobile);
    const { setIsMobile } = useMobile();
    const useOrderConfirm = create(orderConfirm);
    const { apiRecall } = useOrderConfirm();

    return {
      nationNumState,
      layoutModalState,
      soundModalState,
      isDarkMode,
      setDarkMode,
      isModalOpen,
      whatModal,
      setModalName,
      setModalControl,
      setLogin,
      setLogout,
      loginModal,
      alertOpen,
      isPop,
      setMarkList,
      markData,
      setTickData,
      setAggData,
      setOption,
      apiLangCode,
      setTradeData,
      setMarkData,
      serverLoading,
      setIsMainTrue,
      loginState,
      serviceCheck,
      setServiceCheck,
      setChartOpen,
      setIsMobile,
      apiRecall
    };
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  },
  mounted() {
    if (localStorage.getItem('fubit_theme') == 'dark') {
      this.setDarkMode();
    } else if (localStorage.getItem('fubit_theme') == 'light') {
      return;
    } else {
      if (this.agent.toLowerCase().indexOf('samsung') !== -1) {
        return;
      } else {
        const isBrowserDarkMode = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches;
        if (isBrowserDarkMode == true) {
          this.setDarkMode();
        }
      }
    }
    window.scrollTo(0, 1);
  },
  watch: {
    apiRecall() {
      // this.socketConnection.aggTrade.onclose();
      // this.socketConnection.ticker.onclose();
      // this.socketConnection.mark.onclose();
      // this.socketConnection.depth.onclose();
      this.socketConnect();
    },
    whatModal(a) {
      if (this.isModalOpen == true) {
        this.selectedModal = a;
      }
    },
    $route(to, from) {
      window.scrollTo({ y: 0, behavior: 'smooth' });
      if (to.path !== '/') {
        this.setIsMainTrue();
      }
    },
    loginState(a, b) {
      if (a !== b && a == false) {
        this.loading = false;
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    },
    socketRefresh(a) {
      if (a) {
        this.socketConnection.onclose();
      }
      setTimeout(() => {
        this.socketRefresh = false;
      }, 2000);
    }
  },
  beforeMount() {
    //로그인 토큰 유무 검증
    const localToken = localStorage.getItem('fubit_token');
    if (localToken !== null) {
      this.setLogin();
    }
    // 언어 감지
    const local = localStorage.getItem('fubit_lang');
    if (local !== null) {
      if (local === 'en') {
        this.$i18n.locale = 'en';
      } else if (local === 'ru') {
        this.$i18n.locale = 'ru';
      } else if (local === 'zh') {
        this.$i18n.locale = 'zh';
      } else if (local === 'ja') {
        this.$i18n.locale = 'ja';
      } else if (local === 'vi') {
        this.$i18n.locale = 'vi';
      } else if (local === 'es') {
        this.$i18n.locale = 'es';
      } else if (local === 'ko') {
        this.$i18n.locale = 'ko';
      }
    }
  },
  methods: {
    makeTickerString(func) {
      let str = "";
      this.tickerArray.map(ticker => {
        if (str.length > 0) {
          str += "/";  
        }
        if (ticker == "pepe" || ticker == "shib") {
          str += "1000" + ticker + "usdt" + func;
        } else {
          str += ticker + "usdt" + func;
        }
      });
      return str;
    },
    onVisibilityChange() {
      if (this.$isMobile() && this.$route.path == '/position') {
        window.location.reload();
      }
    },
    logoutHandle() {
      this.loading = false;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    //시장현황 데이터 모아서 전달
    allMarkPrice() {
      this.tickerArray.map(ticker => {
        this.markPriceApi(ticker);
      });
    },
    //시장현황 데이터 api
    markPriceApi(symbol) {
      let url = `https://fapi.binance.com/fapi/v1/ticker/24hr?symbol=${symbol}usdt`;
      if (symbol == "pepe" || symbol == "shib") {
        url = `https://fapi.binance.com/fapi/v1/ticker/24hr?symbol=1000${symbol}usdt`;
      }
      const config = {
        method: 'get',
        url: url,
        headers: {}
      };

      axios(config).then((res) => {
        const resData = res.data;
        this.allMarkData[symbol] = resData;
        /*if (symbol === 'btc') {
          this.allMarkData.btc = resData;
        } else if (symbol === 'eth') {
          this.allMarkData.eth = resData;
        } else if (symbol === 'xrp') {
          this.allMarkData.xrp = resData;
        } else if (symbol === 'doge') {
          this.allMarkData.doge = resData;
        } else if (symbol === 'matic') {
          this.allMarkData.matic = resData;
        } else if (symbol === 'arb') {
          this.allMarkData.arb = resData;
        } else if (symbol === 'sei') {
          this.allMarkData.sei = resData;
        } else if (symbol === 'sol') {
          this.allMarkData.sol = resData;
        } else if (symbol === 'stx') {
          this.allMarkData.stx = resData;
        } else if (symbol === 'avax') {
          this.allMarkData.avax = resData;
        } else if (symbol === 'gas') {
          this.allMarkData.gas = resData;
        } else if (symbol === 'axs') {
          this.allMarkData.axs = resData;
        } else if (symbol === 'pol') {
          this.allMarkData.pol = resData;
        } else if (symbol === 'etc') {
          this.allMarkData.etc = resData;
        } else if (symbol === 'ens') {
          this.allMarkData.ens = resData;
        } else if (symbol === 'sui') {
          this.allMarkData.sui = resData;
        } else if (symbol === 'apt') {
          this.allMarkData.apt = resData;
        } else if (symbol === 'sand') {
          this.allMarkData.sand = resData;
        } else if (symbol === '1000pepe') {
          this.allMarkData.pepe = resData;
        } else if (symbol === '1000shib') {
          this.allMarkData.shib = resData;
        } else if (symbol === 'zro') {
          this.allMarkData.zro = resData;
        } else if (symbol === 'uni') {
          this.allMarkData.uni = resData;
        } else if (symbol === 'ondo') {
          this.allMarkData.ondo = resData;
        } else if (symbol === 'jasmy') {
          this.allMarkData.jasmy = resData;
        } else if (symbol === 'mew') {
          this.allMarkData.mew = resData;
        }*/
      });
    },
    //바이낸스 소켓연결
    socketConnect() {
      //메인화면 시장현황 조회 소켓 연결
      this.socketConnection.aggTrade = new WebSocket(
        //'wss://fstream.binance.com/stream?streams=btcusdt@aggTrade/ethusdt@aggTrade/xrpusdt@aggTrade/dogeusdt@aggTrade/arbusdt@aggTrade/solusdt@aggTrade/stxusdt@aggTrade/seiusdt@aggTrade/gasusdt@aggTrade/avaxusdt@aggTrade/axsusdt@aggTrade/polusdt@aggTrade'
        'wss://fstream.binance.com/stream?streams=' + this.makeTickerString("@aggTrade")
      );
      this.socketConnection.aggTrade.onopen = (event) => {
        console.log(event);
        this.socketConnection.aggTrade.onmessage = (data) => {
          const resData = JSON.parse(data.data);
          //aggTrade 값 store 저장
          this.setAggData(resData.data, resData.data.s.toLowerCase().replace("usdt","").replace("1000",""));
          if (resData.data.s === 'BTCUSDT') {
            this.transTime(resData.data.T);
          }
          this.socketConnection.aggTrade.onclose = async (e) => {
            console.log('closed socket');
            this.socketConnect();
          };
        };
      };
      //ticker 값 store 저장
      this.socketConnection.ticker = new WebSocket(
        //'wss://fstream.binance.com/stream?streams=btcusdt@ticker/ethusdt@ticker/xrpusdt@ticker/dogeusdt@ticker/arbusdt@ticker/solusdt@ticker/stxusdt@ticker/seiusdt@ticker/gasusdt@ticker/avaxusdt@ticker/axsusdt@ticker/polusdt@ticker'
        'wss://fstream.binance.com/stream?streams=' + this.makeTickerString("@ticker")
      );
      this.socketConnection.ticker.onopen = (event) => {
        console.log(event);
        this.socketConnection.ticker.onmessage = (data) => {
          const resData = JSON.parse(data.data);
          this.setTickData(resData.data, resData.data.s.toLowerCase().replace("usdt","").replace("1000",""));
          if (resData.data.s === 'BTCUSDT') {
            this.transTime(resData.data.T);
          }
          this.socketConnection.ticker.onclose = async (e) => {
            console.log('closed socket');
            this.socketConnect();
          };
        };
      };

      this.socketConnection.depth = new WebSocket(
        //'wss://fstream.binance.com/stream?streams=btcusdt@depth20/ethusdt@depth20/xrpusdt@depth20/dogeusdt@depth20/arbusdt@depth20/solusdt@depth20/stxusdt@depth20/seiusdt@depth20/gasusdt@depth20/avaxusdt@depth20/axsusdt@depth20/polusdt@depth20'
        'wss://fstream.binance.com/stream?streams=' + this.makeTickerString("@depth20")
      );
      this.socketConnection.depth.onopen = (event) => {
        console.log(event);
        this.socketConnection.depth.onmessage = (data) => {
          const resData = JSON.parse(data.data);
          this.setTradeData(resData.data, resData.data.s.toLowerCase().replace("usdt","").replace("1000",""));
          this.socketConnection.depth.onclose = async (e) => {
            console.log('closed socket');
            this.socketConnect();
          };
        };
      };

      // 시장가 데이터 store 저장
      this.socketConnection.mark = new WebSocket(
        //'wss://fstream.binance.com/stream?streams=btcusdt@markPrice@1s/ethusdt@markPrice@1s/xrpusdt@markPrice@1s/dogeusdt@markPrice@1s/arbusdt@markPrice@1s/solusdt@markPrice@1s/stxusdt@markPrice@1s/seiusdt@markPrice@1s/gasusdt@markPrice@1s/avaxusdt@markPrice@1s/axsusdt@markPrice@1s/polusdt@markPrice@1s'
        'wss://fstream.binance.com/stream?streams=' + this.makeTickerString("@markPrice@1s")
      );
      this.socketConnection.mark.onopen = (event) => {
        console.log(event);
        this.socketConnection.mark.onmessage = (data) => {
          const resData = JSON.parse(data.data);
          this.setMarkData(resData.data, resData.data.s.toLowerCase().replace("usdt","").replace("1000",""));
          this.socketConnection.mark.onclose = async (e) => {
            console.log('closed socket');
            this.socketConnect();
          };
        };
      };
    },
    // 유저 설정 정보 loading & store
    async userSettingSave() {
      let result = await Request(
        '/api/exs/mypage/v1/request_option',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );

      if (!result) return;

      let { status } = result.API_CODE;

      if (status === '0000') {
        this.setOption(result.option);
      }
    },
    transTime(sec) {
      const hour = String(Math.floor((sec / (1000 * 60 * 60)) % 24)).padStart(
        2,
        '0'
      );
      const min = String(Math.floor((sec / (1000 * 60)) % 60)).padStart(2, '0');
      const second = String(Math.floor((sec / 1000) % 60)).padStart(2, '0');
      this.recentTime = hour + min + second;
      if (this.timeArray.includes(this.recentTime)) {
        this.socketRefresh = true;
        this.setChartOpen(true);
      }
    }
  },
  created() {
    this.allMarkPrice();
    this.setMarkList(this.allMarkData);
    this.socketConnect();
    if (localStorage.getItem('fubit_token') !== null) {
      this.userSettingSave();
    }
    this.agent = navigator.userAgent;
    if (window.innerWidth <= 900) {
      this.setIsMobile();
    }
    setTimeout(() => {
      this.loading = true;
    }, 1000);
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }
});
</script>
<style>
body,
html,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #606060;
  background-color: #f8f9fb;
  letter-spacing: -0.88px;
}
input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}

select::-ms-expand {
  /* 화살표 없애기 for IE10, 11*/
  display: none;
}
@media (max-width: 639px) {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /*화살표 배경 넣기*/
    background: #fff url('@/assets/image/select.png') no-repeat 98% center;
    background-size: 30px;
  }
}

input[type='date'] {
  position: relative;
}
input[type='date']::-webkit-clear-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}
</style>
