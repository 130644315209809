<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 px-6 sm:px-0 bg-[#f2f4f5] bg-opacity-80"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2
          class="text-[30px] sm:font-bold max-sm:flex fold:flex justify-between"
        >
          <div>
            <span class="max-sm:hidden fold:hidden">{{
              $t('trade.modal.margin_mode')
            }}</span>
            <span class="sm:hidden text-[20px] dark:text-[#e5e5e5]">{{
              $t('invest.profitloss.position_setting')
            }}</span>
          </div>
          <button class="sm:hidden" @click="setModalControl">
            <img
              src="@/assets/image/icon_close.png"
              alt=""
              class="w-[35px] dark:hidden"
            />
            <img
              src="@/assets/image/icon_close_d.png"
              alt=""
              class="w-[35px] opacity-0 dark:opacity-100 mt-[-35px] dark:mt-0"
            />
          </button>
        </h2>
        <div class="flex sm:mt-[30px] mt-[20px] sm:text-[20px] text-[15px]">
          <button
            :class="[
              marginValue == 'cross' ? tStyle.typeBtnY : tStyle.typeBtnN
            ]"
            @click="setCross"
          >
            {{ $t('trade.modal.cross') }}
          </button>
          <button
            :class="[
              marginValue == 'isolation' ? tStyle.typeBtnY : tStyle.typeBtnN
            ]"
            @click="setIso"
          >
            {{ $t('trade.modal.isolated') }}
          </button>
        </div>
        <div
          class="text-left sm:text-[20px] max-sm:leading-[22px] fold:leading-[22px] p-4 sm:p-0 text-[13px] max-sm:h-[160px] fold:h-[160px] sm:overflow-y-visible overflow-y-scroll mt-[20px] max-sm:bg-[#f7f8fa] max-sm:dark:bg-[#3b3b43] max-sm:dark:text-[#e5e5e5] fold:bg-[#f7f8fa] fold:dark:bg-[#3b3b43] fold:dark:text-[#e5e5e5]"
        >
          <p>- {{ $t('trade.msg.1') }}</p>
          <div class="sm:flex sm:mt-[20px]">
            <div
              class="max-sm:inline fold:inline mr-2 sm:font-regular font-semibold max-sm:dark:text-[#fff] fold:dark:text-[#fff] sm:shrink-0 sm:basis-1/6"
            >
              {{ $t('trade.modal.cross2') }} :
            </div>
            <div
              class="sm:flex sm:flex-col max-sm:text-left max-sm:inline fold:text-left fold:inline sm:basis-5/6"
            >
              <p class="max-sm:inline fold:inline">{{ $t('trade.msg.2') }}</p>
              <p class="max-sm:inline fold:inline">{{ $t('trade.msg.3') }}</p>
            </div>
          </div>
          <div class="sm:flex sm:mt-[20px]">
            <div
              class="max-sm:inline fold:inline mr-2 sm:font-regular font-semibold max-sm:dark:text-[#fff] fold:dark:text-[#fff] sm:shrink-0 sm:basis-1/6"
            >
              {{ $t('trade.modal.isolated2') }} :
            </div>
            <div
              class="sm:flex sm:flex-col max-sm:text-left max-sm:inline sm:basis-5/6"
            >
              <p class="max-sm:inline fold:inline">{{ $t('trade.msg.4') }}</p>
              <p class="max-sm:inline fold:inline">{{ $t('trade.msg.5') }}</p>
            </div>
          </div>
          <p class="sm:mt-[20px] text-[#fc0d1b]">! {{ $t('trade.msg.6') }}</p>
        </div>
      </div>

      <!-- 모바일 레버리지 -->
      <!-- <div class="mt-[40px] sm:hidden text-[15px]">
        {{ $t('deal.leverage3') }}
        <div
          class="border border-[#c9c9c9] mt-[10px] mb-[20px] font-bold text-center py-1 dark:border-[#606060] dark:text-[#fff]"
        >
          <div
            class="w-[16px] h-[16px] ml-4 cursor-pointer"
            @click="minusCount"
          >
            <img class="dark:hidden" src="@/assets/image/w-.png" alt="" />
            <img class="hidden dark:block" src="@/assets/image/d-.png" alt="" />
          </div>
          <div>{{ longValue }}x</div>
          <div class="w-[16px] h-[16px] mr-4 cursor-pointer" @click="plusCount">
            <img class="dark:hidden" src="@/assets/image/w+.png" alt="" />
            <img class="hidden dark:block" src="@/assets/image/d+.png" alt="" />
          </div>
        </div>
        <VueSlider
          :processStyle="processStyle"
          v-model="longValue"
          :dotSize="10"
          :min="1"
          :max="maxValue"
          tooltip="always"
          :tooltip-formatter="'{value}%'"
          :tooltipStyle="tooltipStyle"
          :dotOptions="dotOptions"
          :stepActiveStyle="stepActiveStyle"
          :stepStyle="stepStyle"
          :marks="marks"
          v-if="isDark === false"
        />
        <VueSlider
          :processStyle="darkProcessStyle"
          v-model="longValue"
          :dotSize="10"
          :min="1"
          :max="maxValue"
          tooltip="always"
          :tooltip-formatter="'{value}%'"
          :tooltipStyle="darkToolTipStyle"
          :dotOptions="darkDotOptions"
          :stepStyle="stepStyle"
          :stepActiveStyle="darkStepActiveStyle"
          :marks="marks"
          v-else
        />
      </div> -->

      
      <div class="flex w-full items-center m-auto sm:px-2 pb-6 sm:pb-0">
        <button
          class="sm:hidden basis-1/2 text-[15px] py-1 mt-[30px] border border-[#cfe4fe] dark:bg-[#fff] dark:text-[#333] dark:border-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="sm:basis-full basis-1/2 py-1 sm:py-0 max-sm:text-[15px] fold:text-[15px] sm:h-[60px] mt-[30px] sm:rounded-[3px] bg-[#cfe4fe] sm:dark:bg-[#ffb500] dark:text-[#333] ml-2 sm:ml-2 dark:bg-[#fdd26a]"
          @click="leverageSett"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, darkMode } from '@/store/store';
import { leverage } from '@/store/positionStore';
import { margin } from '@/store/positionStore';
import create from 'vue-zustand';
import VueSlider from 'vue-3-slider-component';

export default {
  name: 'MarginMethod',
  data() {
    return {
      //coinList: ['btc', 'eth', 'xrp', 'matic', 'doge','arb','sol','stx','sei','gas','avax','axs','pol',"etc","ens","sui","apt","sand","pepe","shib","zro","uni","ondo","jasmy","mew"],
      coinList: ['btc', 'eth', 'xrp', 'matic', 'doge','arb','sol','stx','sei','gas','avax','axs','pol',"sui","pepe","shib"],
      selectSymbol: 'btc',
      isDark: null,
      marginValue: '',
      stepStyle: {
        display: 'block',
        width: '10px',
        borderRadius: '50%',
        height: '10px',
        backgroundColor: '#cccccc',
        marginTop: '-3px',
        marginLeft: '-5px'
      },
      stepActiveStyle: {
        display: 'block',
        width: '10px',
        borderRadius: '50%',
        height: '10px',
        backgroundColor: '#fc0d1b',
        marginTop: '-3px',
        marginLeft: '-5px'
      },
      darkStepActiveStyle: {
        display: 'block',
        width: '10px',
        borderRadius: '50%',
        height: '10px',
        backgroundColor: '#24ae64',
        marginTop: '-3px',
        marginLeft: '-5px'
      },
      processStyle: {
        backgroundColor: '#fc0d1b'
      },
      darkProcessStyle: {
        backgroundColor: '#24ae64'
      },
      dotOptions: {
        style: { backgroundColor: '#fc0d1b' }
      },
      darkDotOptions: {
        style: { backgroundColor: '#24ae64' }
      },
      tooltipStyle: {
        color: '#ffffff',
        backgroundColor: '#fc0d1b',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#fc0d1b'
      },
      darkToolTipStyle: {
        color: '#ffffff',
        backgroundColor: '#24ae64',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#24ae64'
      },
      longValue: null,
      tStyle: {
        inner:
          'bg-white sm:w-[580px] px-6 m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-6 relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col sm:leading-[26px]',
        title: 'sm:text-center sm:mt-[50px] mt-[30px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden',
        typeBtnN:
          'basis-1/2 sm:bg-[#e8eff7] bg-[#f2f4f5] sm:rounded py-2 sm:mx-2 sm:dark:text-[#333] sm:dark:bg-[#fff8e8] dark:bg-[#fff] max-sm:text-[#606060] fold:text-[#606060] dark:text-[#9f9f9f]',
        typeBtnY:
          'basis-1/2 sm:bg-[#cfe4fe] bg-[#005cd3] max-sm:text-[#fff] fold:text-[#fff] sm:rounded py-2 sm:mx-2 dark:text-[#333] dark:bg-[#fdd26a]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();
    const useLeverage = create(leverage);
    const { longLeverage, setLongLev } = useLeverage();
    const useMargin = create(margin);
    const { setMarginType, marginType } = useMargin();
    const useDarkMode = create(darkMode);
    const { isDarkMode } = useDarkMode();

    return {
      setModalControl,
      setMarginType,
      marginType,
      longLeverage,
      setLongLev,
      isDarkMode
    };
  },
  mounted() {
    this.marginValue = this.marginType;
    this.longValue = parseInt(this.longLeverage);
    this.isDark = this.isDarkMode;
  },
  created() {
    const session = sessionStorage.getItem('fubit_symbol');
    if (session !== null) {
      this.selectSymbol = this.coinList[session - 1];
    }
  },
  methods: {
    setCross() {
      this.marginValue = 'cross';
    },
    setIso() {
      this.marginValue = 'isolation';
    },
    leverageSett() {
      this.setLongLev(this.longValue);
      sessionStorage.setItem('fubit_margin', this.marginValue);
      sessionStorage.setItem('fubit_long', this.longValue);
      this.setMarginType(this.marginValue);
      this.setModalControl();
    },
    plusCount() {
      if (this.longValue !== this.maxValue) {
        this.longValue++;
      }
    },
    minusCount() {
      if (this.longValue !== 1) {
        this.longValue--;
      }
    }
  },
  components: {
    VueSlider
  },
  computed: {
    maxValue() {
      if (this.selectSymbol === 'btc') {
        return 125;
      } else if (
        this.selectSymbol === 'eth' ||
        this.selectSymbol === 'sol') {
        return 100;
      } else if (
        this.selectSymbol === 'xrp' ||
        this.selectSymbol === 'avax'
      ) {
        return 75;
      } else if (
        this.selectSymbol === 'doge' ||
        this.selectSymbol === 'matic' ||
        this.selectSymbol === 'arb' ||
        this.selectSymbol === 'sei' ||
        this.selectSymbol === 'gas' ||
        this.selectSymbol === 'axs' ||
        this.selectSymbol === 'pol'
      ) {
        return 50;
      } else if (
        this.selectSymbol === 'stx'
      ) {
        return 25;
      }
    },
    marks() {
      if (this.selectSymbol === 'btc') {
        const a = {
          1: '',
          25: '',
          50: '',
          75: '',
          100: '',
          125: ''
        };
        return a;
      } else if (this.selectSymbol === 'eth') {
        const a = {
          1: '',
          25: '',
          50: '',
          75: '',
          100: ''
        };
        return a;
      } else if (
        this.selectSymbol === 'xrp' ||
        this.selectSymbol === 'avax') {
        const a = {
          1: '',
          15: '',
          30: '',
          45: '',
          60: '',
          75: ''
        };
        return a;
      } else if (
        this.selectSymbol === 'doge' ||
        this.selectSymbol === 'matic' ||
        this.selectSymbol === 'arb' ||
        this.selectSymbol === 'sei' ||
        this.selectSymbol === 'gas' ||
        this.selectSymbol === 'axs' ||
        this.selectSymbol === 'pol'
      ) {
        const a = {
          1: '',
          10: '',
          20: '',
          30: '',
          40: '',
          50: ''
        };
        return a;
      } else if (this.selectSymbol === 'stx') {
        const a = {
          1: '',
          5: '',
          10: '',
          15: '',
          20: '',
          25: ''
        };
        return a;
      }
    }
  }
};
</script>
<style lang=""></style>
