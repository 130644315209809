import { createStore } from 'zustand/vanilla';
import produce from 'immer';

//leverage 데이터
export const leverage = createStore((set) => ({
  longLeverage: '25',
  shortLeverage: '25',
  setLongLev(data) {
    set(() => ({
      longLeverage: data
    }));
  },
  setShortLev(data) {
    set(() => ({
      shortLeverage: data
    }));
  }
}));

//증거금 방식 margin 데이터
export const margin = createStore((set) => ({
  marginType: 'cross',
  setMarginType(data) {
    set(() => ({
      marginType: data
    }));
  }
}));

//aggTrade 소켓 데이터 여기다 다 저장
export const aggSocketData = createStore((set) => ({
  btcAggData: {},
  ethAggData: {},
  xrpAggData: {},
  maticAggData: {},
  dogeAggData: {},
  arbAggData: {},
  seiAggData: {},
  solAggData: {},
  stxAggData: {},
  avaxAggData: {},
  gasAggData: {},
  axsAggData: {},
  polAggData: {},
  etcAggData: {},
  ensAggData: {},
  suiAggData: {},
  aptAggData: {},
  sandAggData: {},
  pepeAggData: {},
  shibAggData: {},
  zroAggData: {},
  uniAggData: {},
  ondoAggData: {},
  jasmyAggData: {},
  mewAggData: {},
  setAggData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcAggData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethAggData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpAggData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticAggData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeAggData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbAggData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiAggData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solAggData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxAggData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxAggData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasAggData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsAggData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polAggData: data
      }));
    } else if (symbol == 'etc') {
      set(() => ({
        etcAggData: data
      }));
    } else if (symbol == 'ens') {
      set(() => ({
        ensAggData: data
      }));
    } else if (symbol == 'sui') {
      set(() => ({
        suiAggData: data
      }));
    } else if (symbol == 'apt') {
      set(() => ({
        aptAggData: data
      }));
    } else if (symbol == 'sand') {
      set(() => ({
        sandAggData: data
      }));
    } else if (symbol == 'pepe') {
      set(() => ({
        pepeAggData: data
      }));
    } else if (symbol == 'shib') {
      set(() => ({
        shibAggData: data
      }));
    } else if (symbol == 'zro') {
      set(() => ({
        zroAggData: data
      }));
    } else if (symbol == 'uni') {
      set(() => ({
        uniAggData: data
      }));
    } else if (symbol == 'ondo') {
      set(() => ({
        ondoAggData: data
      }));
    } else if (symbol == 'jasmy') {
      set(() => ({
        jasmyAggData: data
      }));
    } else if (symbol == 'mew') {
      set(() => ({
        mewAggData: data
      }));
    }
  }
}));

// ticker 소켓데이터 여기다 다 저장
export const tickSocketData = createStore((set) => ({
  btcTickData: {},
  ethTickData: {},
  xrpTickData: {},
  maticTickData: {},
  dogeTickData: {},
  arbTickData: {},
  seiTickData: {},
  solTickData: {},
  stxTickData: {},
  avaxTickData: {},
  gasTickData: {},
  axsTickData: {},
  polTickData: {},
  etcTickData: {},
  ensTickData: {},
  suiTickData: {},
  aptTickData: {},
  sandTickData: {},
  pepeTickData: {},
  shibTickData: {},
  zroTickData: {},
  uniTickData: {},
  ondoTickData: {},
  jasmyTickData: {},
  mewTickData: {},
  setTickData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcTickData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethTickData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpTickData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticTickData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeTickData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbTickData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiTickData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solTickData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxTickData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxTickData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasTickData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsTickData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polTickData: data
      }));
    } else if (symbol == 'etc') {
      set(() => ({
        etcTickData: data
      }));
    } else if (symbol == 'ens') {
      set(() => ({
        ensTickData: data
      }));
    } else if (symbol == 'sui') {
      set(() => ({
        suiTickData: data
      }));
    } else if (symbol == 'apt') {
      set(() => ({
        aptTickData: data
      }));
    } else if (symbol == 'sand') {
      set(() => ({
        sandTickData: data
      }));
    } else if (symbol == 'pepe') {
      set(() => ({
        pepeTickData: data
      }));
    } else if (symbol == 'shib') {
      set(() => ({
        shibTickData: data
      }));
    } else if (symbol == 'zro') {
      set(() => ({
        zroTickData: data
      }));
    } else if (symbol == 'uni') {
      set(() => ({
        uniTickData: data
      }));
    } else if (symbol == 'ondo') {
      set(() => ({
        ondoTickData: data
      }));
    } else if (symbol == 'jasmy') {
      set(() => ({
        jasmyTickData: data
      }));
    } else if (symbol == 'mew') {
      set(() => ({
        mewTickData: data
      }));
    }
  }
}));

// 호가창 소켓 데이터
export const tradeSocketData = createStore((set) => ({
  btcTradeData: {},
  ethTradeData: {},
  xrpTradeData: {},
  maticTradeData: {},
  dogeTradeData: {},
  arbTradeData: {},
  seiTradeData: {},
  solTradeData: {},
  stxTradeData: {},
  avaxTradeData: {},
  gasTradeData: {},
  axsTradeData: {},
  polTradeData: {},
  etcTradeData: {},
  ensTradeData: {},
  suiTradeData: {},
  aptTradeData: {},
  sandTradeData: {},
  pepeTradeData: {},
  shibTradeData: {},
  zroTradeData: {},
  uniTradeData: {},
  ondoTradeData: {},
  jasmyTradeData: {},
  mewTradeData: {},
  setTradeData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcTradeData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethTradeData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpTradeData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticTradeData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeTradeData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbTradeData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiTradeData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solTradeData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxTradeData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxTradeData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasTradeData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsTradeData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polTradeData: data
      }));
    } else if (symbol == 'etc') {
      set(() => ({
        etcTradeData: data
      }));
    } else if (symbol == 'ens') {
      set(() => ({
        ensTradeData: data
      }));
    } else if (symbol == 'sui') {
      set(() => ({
        suiTradeData: data
      }));
    } else if (symbol == 'apt') {
      set(() => ({
        aptTradeData: data
      }));
    } else if (symbol == 'sand') {
      set(() => ({
        sandTradeData: data
      }));
    } else if (symbol == 'pepe') {
      set(() => ({
        pepeTradeData: data
      }));
    } else if (symbol == 'shib') {
      set(() => ({
        shibTradeData: data
      }));
    } else if (symbol == 'zro') {
      set(() => ({
        zroTradeData: data
      }));
    } else if (symbol == 'uni') {
      set(() => ({
        uniTradeData: data
      }));
    } else if (symbol == 'ondo') {
      set(() => ({
        ondoTradeData: data
      }));
    } else if (symbol == 'jasmy') {
      set(() => ({
        jasmyTradeData: data
      }));
    } else if (symbol == 'mew') {
      set(() => ({
        mewTradeData: data
      }));
    }
  }
}));

// 시장가 소켓 데이터
export const markSocketData = createStore((set) => ({
  btcMarkData: {},
  ethMarkData: {},
  xrpMarkData: {},
  maticMarkData: {},
  dogeMarkData: {},
  arbMarkData: {},
  seiMarkData: {},
  solMarkData: {},
  stxMarkData: {},
  avaxMarkData: {},
  gasMarkData: {},
  axsMarkData: {},
  polMarkData: {},
  etcMarkData: {},
  ensMarkData: {},
  suiMarkData: {},
  aptMarkData: {},
  sandMarkData: {},
  pepeMarkData: {},
  shibMarkData: {},
  zroMarkData: {},
  uniMarkData: {},
  ondoMarkData: {},
  jasmyMarkData: {},
  mewMarkData: {},
  setMarkData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcMarkData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethMarkData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpMarkData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticMarkData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeMarkData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbMarkData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiMarkData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solMarkData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxMarkData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxMarkData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasMarkData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsMarkData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polMarkData: data
      }));
    } else if (symbol == 'etc') {
      set(() => ({
        etcMarkData: data
      }));
    } else if (symbol == 'ens') {
      set(() => ({
        ensMarkData: data
      }));
    } else if (symbol == 'sui') {
      set(() => ({
        suiMarkData: data
      }));
    } else if (symbol == 'apt') {
      set(() => ({
        aptMarkData: data
      }));
    } else if (symbol == 'sand') {
      set(() => ({
        sandMarkData: data
      }));
    } else if (symbol == 'pepe') {
      set(() => ({
        pepeMarkData: data
      }));
    } else if (symbol == 'shib') {
      set(() => ({
        shibMarkData: data
      }));
    } else if (symbol == 'zro') {
      set(() => ({
        zroMarkData: data
      }));
    } else if (symbol == 'uni') {
      set(() => ({
        uniMarkData: data
      }));
    } else if (symbol == 'ondo') {
      set(() => ({
        ondoMarkData: data
      }));
    } else if (symbol == 'jasmy') {
      set(() => ({
        jasmyMarkData: data
      }));
    } else if (symbol == 'mew') {
      set(() => ({
        mewMarkData: data
      }));
    }
  }
}));

//청산하기 모달 연동 데이터
export const payoffData = createStore((set) => ({
  payState: {
    pSymbol: '',
    pMarginT: '',
    pDest: '',
    pLeverage: '',
    pAverage: '',
    pQuantity: ''
  },
  setPayOff(obj) {
    set(() => ({
      payState: obj
    }));
  }
}));

//주문 확인서 데이터 저장
export const orderConfirm = createStore((set) => ({
  apiRecall: false,
  orderData: {
    position: '',
    symbol: '',
    type: '',
    dest: '',
    price: '',
    leverage: '',
    margin: '',
    quantity: '',
    trigger: '',
    actionPrice: ''
  },
  setOrderData(data) {
    set(() => ({
      orderData: data
    }));
  },
  setApiCall() {
    set((state) => ({
      apiRecall: !state.apiRecall
    }));
  }
}));

//증거금 수정 모달 데이터
export const marginEdit = createStore((set) => ({
  ableAsset: {},
  setAbleAsset(data) {
    set(() => ({
      ableAsset: data
    }));
  }
}));

// 주문내역 상세 체결 조회
export const orderDetail = createStore((set) => ({
  orderDetailState: { orderSeq: '' },
  setOrderSeq(data) {
    set(
      produce((state) => {
        state.orderDetailState.orderSeq = data;
      })
    );
  }
}));

//목표/손절가 모달 데이터
export const stopLimit = createStore((set) => ({
  stopLimitData: {},
  setStopLimitData(data) {
    set(() => ({
      stopLimitData: data
    }));
  }
}));

//메인화면 시장현황 데이터
export const markListStore = createStore((set) => ({
  markData: {},
  setMarkList(data) {
    set(() => ({
      markData: data
    }));
  }
}));

// 거래하기 하단 리스트 데이터 전달
export const tableData = createStore((set) => ({}));

// 거래하기 차트 리랜더링
export const chartRender = createStore((set) => ({
  chartOpen: false,
  setChartOpen(data) {
    set(() => ({
      chartOpen: data
    }));
  }
}));

// 포지션 리스트
export const positionList = createStore((set) => ({
  positionData: [],
  lineRefresh: false,
  setPosition(data) {
    set(() => ({
      positionData: data
    }));
  },
  setLineRefresh() {
    set((state) => ({
      lineRefresh: !state.lineRefresh
    }));
  }
}));
